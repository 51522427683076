.form-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  fieldset {
    border: 0;
  }
  .MuiInputBase-root {
    width: 30%;
    background-color: #ddd;
    border: 0;
    border-radius: 8px;
  }
  .MuiInputBase-input {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .MuiFormControl-root {
    .MuiInputBase-root {
      width: 100%;
    }
  }
  .MuiFormControl-root .MuiInputBase-formControl {
    .MuiInputBase-input {
      width: 100%;
      color: #3c434b;
    }
  }
  .input-field-block {
    display: flex;
    align-items: center;
    gap: 20px;
  }
}
.btn-block {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  align-items: center;
  .MuiButton-root {
    background-color: #3c735b;
    color: #fff;
    font-size: 16px;
    border-radius: 8px;
    padding-left: 40px;
    padding-right: 40px;
    text-transform: capitalize;
    &:hover {
      background-color: #2d604a;
    }
  }
}
.card-block {
  width: 60%;
  margin: 0 auto !important;
  border: 1px solid #ddd;
  border-radius: 8px;
  h5 {
    background-color: #ccc;
    padding: 10px;
    border-radius: 8px 8px 0px 0px;
  }
  .card-body {
    padding: 30px;
  }
}

.custom-modal {
  button {
    background-color: #272dd3;
    height: 40px;
    border: 0;
    border-radius: 8px;
    color: #fff;
    width: 100%;
    text-transform: uppercase;
  }
}
.AccordionTab {
  margin-bottom: 15px !important;
  box-shadow: unset !important;
  border: 1px solid #d9d9d9;
  border-radius: 5px !important;
  .MuiAccordionSummary-gutters {
    min-height: unset !important;
    background-color: #d9d9d9 !important;
    padding: 5px 30px;
    height: 40px;
    .MuiAccordionSummary-content {
      margin: 0;
      h5 {
        font-size: 18px;
        line-height: 20px;
        font-weight: 700;
      }
    }
    .MuiAccordionSummary-expandIconWrapper {
      background-color: #3c735c;
      width: 29px;
      height: 29px;
      border-radius: 100%;
      position: relative;
      svg {
        display: none;
      }
      &::before,
      &::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        background-color: #fff;
        border-radius: 2px;
        transform: translate(-50%, -50%);
      }
      &::before {
        width: 70%;
        height: 3px;
      }
      &::after {
        height: 70%;
        width: 3px;
        opacity: 1;
      }
    }
  }
  &.Mui-expanded {
    .MuiAccordionSummary-expandIconWrapper {
      &::after {
        opacity: 0;
      }
    }
  }
  &_details {
    padding: 10px 30px !important;
  }
}
.BoxLabel {
  font-size: 14px !important;
  line-height: 16px !important;
  font-weight: 500 !important;
  color: #3c434b !important;
}

.BoxLabel-dark {
  font-size: 14px !important;
  line-height: 16px !important;
  font-weight: 500 !important;
  color: #fff !important;
}

.Channel {
  gap: 15px;
  &_Sidebar {
    max-width: 267px;
    width: 100%;
    overflow: hidden;
    button {
      background-color: #aeaeae !important;
      border-bottom: 1px solid #cfcfcf;
      justify-content: flex-start !important;
      &.Mui-selected {
        background-color: #3c735c !important;
        border-right: none;
        border-left: none;
      }
      &:first-child {
        border-radius: 10px 10px 0 0;
      }
      &:last-child {
        border-bottom: 0;
        border-radius: 0 0 10px 10px;
      }
      p {
        font-size: 16px;
        line-height: 18px;
        font-weight: 500;
      }
    }
    .MuiTabs-indicator {
      background-color: transparent;
    }
  }
  &_Body {
    width: calc(100% - 282px);
    padding: 0 100px;
  }
}

.zoneTabbing {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .tablist {
    border-radius: 50px;
    min-height: unset;
    margin-bottom: 22px;
    button {
      width: 105px !important;
      flex: unset !important;
      background-color: #aeaeae !important;
      color: #3c434b !important;
      font-size: 16px;
      line-height: 20px;
      font-weight: 400;
      padding: 10px !important;
      min-height: unset !important;
      border: none !important;
      &.Mui-selected {
        color: #ffffff !important;
        background: #3b5c84 !important;
      }
    }
  }
  .MuiTabs-indicator {
    background-color: transparent;
  }
}
.TabbingDetails {
  padding: 40px 95px;
  &_innerBox {
    max-width: 847px;
  }
}
.CustomSelectBox {
  .MuiInputBase-root {
    border: 2px solid #bcbcbc;
    padding: 0 !important;
    border-radius: 9px;
    input {
      padding: 6px 60px 6px 10px !important;
      font-size: 14px;
      line-height: normal;
    }
  }
  fieldset {
    border: none;
  }
}
.UploadFile {
  display: grid;
  gap: 18px;
  align-items: center;
  grid-template-columns: 1fr 40px;
}
.CustomCheckBox {
  position: relative;
  .MuiFormControlLabel-root {
    margin: 0;
    width: fit-content;
    .MuiButtonBase-root {
      padding: 15px;
      svg {
        display: none;
      }
      .MuiTouchRipple-root {
        display: block;
        width: 32px;
        height: 32px;
        border: 2px solid #bcbcbc;
        border-radius: 10px;
      }
      &.Mui-checked {
        .MuiTouchRipple-root {
          background-color: #3c735b;
          border: 2px solid #2d604a;
          &:before {
            content: "";
            position: absolute;
            width: 22px;
            height: 11px;
            top: 6px;
            border: 3px solid #fff;
            border-radius: 0 0 0 4px;
            border-top: 0;
            border-right: 0;
            transform: rotate(-45deg);
            left: 3px;
          }
        }
      }
    }
  }
}
.CustomNumber {
  .MuiFormControl-root {
    width: 100%;
    max-width: 100%;
    .MuiInputBase-root {
      border: none;
      border-radius: 9px;
      input {
        // background-color: #e0e0e0;
        border: none;
        padding: 6px 15px;
        font-size: 14px;
      }
    }
    .Mui-focused {
      box-shadow: unset !important;
    }
  }
}

.CustomNumberDark {
  .MuiFormControl-root {
    width: 100%;
    max-width: 100%;
    .MuiInputBase-root {
      border: none;
      border-radius: 9px;
      input {
        background-color: #000000;
        border: none;
        padding: 6px 15px;
        font-size: 14px;
      }
    }
    .Mui-focused {
      box-shadow: unset !important;
    }
  }
}
