input:focus, select:focus{
    border:none;
    box-shadow:none;
    outline:none;
  }
  .no-found{
    color: #fff;
    text-align: center;
  }
  .draggableBlock {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .drag-handler{
    cursor: move;
    color: #fff;
  }
  .draggable-item {
    z-index: 99999;
  }