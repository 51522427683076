.modelCloseBtn {
  float: right;
  color: #fff !important;
  font-size: 38px;
}
.dialogForm {
  max-width: 500px;
  margin: 0 auto;
  width: 100%;
  &-field {
    display: grid;
    grid-template-columns: 130px auto;
    gap: 20px;
    align-items: center;
    margin-bottom: 20px;
    &-label {
      font-weight: 400 !important;
      font-size: 14px !important;
      line-height: 16px !important;
      color: #d9d9d9;
    }
    .MuiSelect-select {
      border: 2px solid #e8e8e8 !important;
      border-radius: 9px !important;
      height: 25px !important;
      box-sizing: border-box !important;
      padding: 8px 15px !important;
      font-weight: 400 !important;
      font-size: 14px !important;
      line-height: 16px !important;
      color: #d9d9d9 !important;
    }
  }
}

.dialogTable {
  .MuiTable-root thead tr:first-child {
    background-color: #d9d9d9 !important;
    tr {
      color: #d9d9d9 !important;
    }
  }
}
.dialogTable {
  .MuiTable-root tbody tr {
    background-color: #34393f !important;
    td {
      color: #d9d9d9 !important;
    }
  }
}

.selectBox {
  #simple-select {
    color: #e8e8e8 !important;
  }
}
.divider {
  font-size: 30px;
}
// .addNewwheelDialog
.addNwWheelDialog {
  .MuiPaper-root {
    background: #f2f2f2 !important;
    color: #484c52;
    .MuiDialog-paper {
      min-width: 676px;
      background: #f2f2f2;
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.35);
      border-radius: 10px;
      padding: 35px !important;
    }
    &-title {
      font-weight: 700 !important;
      font-size: 20px !important;
      line-height: 23px !important;
      color: #484c52;
      margin: 0 0 10px !important;
    }
    .MuiDialogActions-root {
      padding: 0;
      margin-top: 30px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 25px;
      button {
        margin: 0 !important;
        background: #3c735c;
        border-radius: 15px;
        box-shadow: none;
        height: 40px;
        min-width: 125px;
        padding: 12px;
        border: 2px solid #3c735c;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: #ffffff;
        text-transform: capitalize;
        &:last-child {
          color: #484c52;
          background: transparent;
          border: 2px solid #3c735c;
        }
      }
    }
  }
}

.modal-new-user {
  padding: 20px;
  #selectGroupBox {
    width: 100%;
  }
}

.user-group-select,
.familyNameInput,
.userComment {
  width: 100% !important;
}

.selectHeading {
  color: #c66969;
}
.user-group-select {
  & .MuiOutlinedInput-notchedOutline {
    color: #34393f;
  }
  &.MuiOutlinedInput-root {
    color: #484c52;
  }
}
.modal-new-wheel-form {
  .MuiTypography-root {
    width: max-content;
  }
}
