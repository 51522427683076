.channel-break-container {
  display: flex;
  align-items: center;
  margin-top: 15px;
}
.channel-break {
  display: flex;
  align-items: center;
  margin-right: 35px;
  justify-content: space-between;
  &:last-child {
    margin: 0;
  }
  span,
  .Max {
    font-size: 14px;
    white-space: nowrap;
  }
  .Max {
   margin-right: 10px;
  }

  .MuiInputBase-input{
    padding: 4px 0px 4px 14px;
    border-radius: 9px;
  }
}
  

