.CustomerTabsTop {
    .MuiTabs-flexContainer {
        overflow: auto;
        button {
            width: 100%;
            max-width: 228px;
        }
    }
}
.tabOverflow {
    .MuiTabs-flexContainer {
        overflow: auto;
        button {
            min-width: fit-content;
        }
    }
}