.content-filter {
  background-color: #34393f;
  padding: 0 15px;
  padding-left: 80px;
  display: flex;
  gap: 25px;
  min-height: 54px;
  align-items: center;
  flex-wrap: wrap;
  & > .MuiInputBase-root {
    height: 36px;
    min-width: 270px;
    .MuiSelect-select {
      padding: 8px 15px;
      min-width: 100px;
      color: #fff;
    }
    h5 {
      margin: 0;
    }
    svg {
      color: #fff !important;
    }
    fieldset {
      border: 1px solid #e8e8e8 !important;
      border-radius: 8px;
    }
  }
  .MuiOutlinedInput-root {
    ::placeholder {
      color: #ffffff;
    }
    color: #ffffff;
  }
  .MuiSelect-select, input {
    color: #e8e8e8 !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
    align-items: center;
  }
  .MuiButton-root {
    background: #4d555e;
    font-weight: 500;
    color: #e8e8e8;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 15px 20px;
    height: 54px;
    border-radius: 0;
    text-transform: capitalize;
    min-width: 130px;
    &:hover {
      background-color: #3c735b;
    }
  }
}
.ButtonDelete {
  background: #3e474e;
  font-weight: 500;
  font-size: 18px;
  color: #ffffff;
  padding: 15px 32px;
  margin-top: -15px;
  position: relative;
  min-height: 70px;
  border-radius: 0;
  text-transform: capitalize;
  &:hover {
    background-color: #8f0000;
  }
}
.content-filter-inner {
  background-color: #34393f;
  display: flex;
  gap: 15px;
  .MuiInputBase-root {
    width: 33.33%;
    .MuiSelect-select {
      padding: 8px 15px;
      min-width: 100px;
      color: #fff;
    }
    h5 {
      margin: 0;
    }
    svg {
      color: #fff !important;
    }

    fieldset {
      border: 1px solid #fff !important;
    }
  }
}

@media only screen and (max-width: 1240px) {
  .content-filter {
    padding: 24px;
  }
}
