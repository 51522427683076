.listTable {
  table {
    th,
    td {
      color: #3c434b;
      font-size: 14px;
      font-weight: 400;
      line-height: normal;
      padding: 10px 22px;
      height: 50px;
      border-bottom: none;
      border-right: 1px solid #cfcfcf;
      height: auto;
      .Mui-TableHeadCell-Content {
        height: 100%;
        justify-content: space-between;
      }
      &:first-child {
        border-right: none;
      }
      &:last-child {
        border-right: none;
      }
    }
    thead {
      tr {
        background-color: #cecccc;
        box-shadow: none;
      }
    }
    tbody {
      tr {
        td {
          border-bottom: 1px solid #cfcfcf;
        }
      }
    }
  }
}

.listTable2 {
  table {
    th,
    td {
      color: #000000;
      font-size: 14px;
      font-weight: 400;
      line-height: normal;
      padding: 10px 22px;
      height: 50px;
      border-bottom: none;
      border-right: 1px solid #cfcfcf;
      height: auto;
      .Mui-TableHeadCell-Content {
        height: 100%;
        justify-content: space-between;
      }
      &:first-child {
        border-right: none;
      }
      &:last-child {
        border-right: none;
      }
    }
    thead {
      tr {
        background-color: #cecccc;
        box-shadow: none;
      }
    }
    tbody {
      tr {
        td {
          border-bottom: 1px solid #cfcfcf;
        }
      }
    }
    tr {
      &.Mui-selected {
        background-color: #e3eae4 !important;
      }
    }
  }
}

.listTable3 {
  table {
    td {
      color: #ffffff;
      font-size: 14px;
      font-weight: 400;
      line-height: normal;
      padding: 10px 22px;
      height: 50px;
      border-bottom: none;
      border-right: 1px solid #cfcfcf;
      height: auto;
      .Mui-TableHeadCell-Content {
        height: 100%;
        justify-content: space-between;
      }
      &:first-child {
        border-right: none;
      }
      &:last-child {
        border-right: none;
      }
    }
    thead {
      tr, th {
        background-color: #cecccc;
        color: #000 !important;
        box-shadow: none;
        svg{
          color: #3c434b !important;
        }
      }
    }
    tbody {
      tr {
        td {
          border-bottom: 1px solid #cfcfcf;
        }
      }
    }
    tr {
      &.Mui-selected {
        background-color: #e3eae4 !important;
        &:hover {
          background-color: #e3eae4 !important;
        }
      }
    }
  }
}
.css-141o3ua-MuiButtonBase-root-MuiIconButton-root{
  color: #000 !important;
}

.listTable2 table .MuiSvgIcon-root {
  color: #3c434b;
}

// .tableResponsive {
//   background: pink !important;
// }

.customPagination {
  border: 1px solid #ccc;
  padding: 10px 24px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 15px;
  span,
  input,
  button P {
    color: #3c434b;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
  }
  &__buttons {
    display: flex;
    align-items: center;
    gap: 8px;
  }
}

.loadingDialog .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  background-color: transparent !important;
  overflow: hidden !important;
}

.list_table table tr th div {
  white-space: nowrap;
}
