.ApplicationSetting {
  .card-block {
    width: 100%;
    .MuiButtonBase-root {
      padding: 0;
      background: transparent;
      min-height: unset;
      .MuiAccordionSummary-content {
        margin: 0 !important;
      }
      h5 {
        width: 100%;
        padding-right: 50px;
      }
      .MuiAccordionSummary-expandIconWrapper {
        position: absolute;
        right: 13px;
      }
    }
  }
}

.ApplicationSetting-dark {
  .card-block {
    width: 100%;
    .MuiButtonBase-root {
      padding: 0;
      background: transparent;
      min-height: unset;
      .MuiAccordionSummary-content {
        margin: 0 !important;
      }
      h5 {
        color: #000;
        width: 100%;
        padding-right: 50px;
      }
      .MuiAccordionSummary-expandIconWrapper {
        position: absolute;
        right: 13px;
      }
    }
  }
}
