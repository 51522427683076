.deleteChannelListDialog {
    .MuiDialog-paper {
      max-width: 676px;
      background: #34393f;
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.35);
      border-radius: 10px;
      padding: 35px !important;
    }
    &-title {
      font-weight: 700 !important;
      font-size: 20px !important;
      line-height: 23px !important;
      color: #d9d9d9;
      margin: 0 0 58px !important;
    }
    .MuiDialogActions-root {
      padding: 0;
      margin-top: 30px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 25px;
      button {
        margin: 0 !important;
        background:#3C735C;
        border-radius: 15px;
        box-shadow: none;
        height: 40px;
        min-width: 125px;
        padding: 12px;
        border: 2px solid #3C735C;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: #ffffff;
        text-transform: capitalize;
        &:last-child {
          background: transparent;
          border: 2px solid #fff;
        }
      }
    }
  }

  