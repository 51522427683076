.modelCloseBtn {
  float: right;
  color: #fff !important;
  font-size: 38px;
}
.addNewCategoryDialog {
  .MuiDialog-paper {
    max-width: 800px;
    background: #ffffff;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.35);
    border-radius: 10px;
    padding: 35px !important;
  }

  &-title {
    font-weight: 700 !important;
    font-size: 20px !important;
    line-height: 23px !important;
    color: #d9d9d9;
    margin: 0 0 10px !important;
  }
  .modal-top-actions {
    padding: 0;
    margin-top: 0px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 25px;

    button {
      margin: 0 !important;
      background-color: #3c735c !important;
      border: 1px solid #3c735c !important;
      border-radius: 15px;
      box-shadow: none;
      height: 40px;
      min-width: 125px;
      padding: 12px;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: #ffffff;
      text-transform: capitalize;

      & .MuiSvgIcon-root,
      svg {
        display: block;
      }

      &:first-child {
        background: transparent;
        border: 2px solid #fff;
      }

      &:last-child {
        background: transparent;
        border: 2px solid #fff;
      }
    }

    .MuiButton-outlinedError {
      background-color: transparent !important;
      border: 1px solid #c66969 !important;
    }
  }
}

.dialogForm {
  max-width: 800px;
  margin: 0 auto;
  width: 100%;

  &-field {
    display: grid;
    grid-template-columns: 130px auto;
    gap: 20px;
    align-items: center;
    margin-bottom: 20px;

    &-label {
      font-weight: 400 !important;
      font-size: 14px !important;
      line-height: 16px !important;
      color: #d9d9d9;
    }

    .MuiSelect-select {
      border: 2px solid #e8e8e8 !important;
      border-radius: 9px !important;
      height: 34px !important;
      box-sizing: border-box !important;
      padding: 8px 20px !important;
      font-weight: 400 !important;
      font-size: 14px !important;
      line-height: 16px !important;
      color: #d9d9d9 !important;
    }
  }
}

.dialogTable {
  .MuiTable-root thead tr:first-child {
    background-color: #d9d9d9 !important;

    th{
      color: #000000 !important;
      svg{
        color: #000000 !important;
      }
    }
    tr {
      color: #d9d9d9 !important;
    }
  }
}

.dialogTable {
  .MuiTable-root tbody tr {
    background-color: #34393f !important;

    td {
      color: #d9d9d9 !important;
    }
  }
}

.modal-top-actions {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 25px;

  button {
    margin: 0 !important;
    background: #3c735c;
    border-radius: 15px;
    box-shadow: none;
    height: 40px;
    min-width: 125px;
    padding: 12px;
    border: 2px solid #3c735c;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #ffffff;
    text-transform: capitalize;

    &:last-child {
      background: transparent;
      border: 2px solid #c66969;
    }
  }
}

.selectBox {
  #simple-select {
    color: #e8e8e8 !important;
  }
}

.divider {
  font-size: 30px;
}

.addNewCategoryDialog {
  .MuiDialog-paper {
    min-width: 800px;
    background: #f2f2f2;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.35);
    border-radius: 10px;
    padding: 35px !important;
  }

  &-title {
    font-weight: 700 !important;
    font-size: 20px !important;
    line-height: 23px !important;
    color: #d9d9d9;
    margin: 0 0 10px !important;
  }

  .MuiDialogActions-root {
    padding: 0;
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 25px;

    button {
      margin: 0 !important;
      background: #3c735c;
      border-radius: 15px;
      box-shadow: none;
      height: 40px;
      min-width: 125px;
      padding: 12px;
      border: 2px solid #3c735c;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: #ffffff;
      text-transform: capitalize;

      &:last-child {
        background: transparent;
        border: 2px solid #fff;
      }

      &.settings-btn {
        background: transparent;
        border: 2px solid #fff;
        min-width: auto;
      }
    }
  }
}

.btn-user-role {
  background-color: #34393f;
  text-align: left;

  &.css-toe7b3-MuiButtonBase-root-MuiButton-root {
    background-color: #34393f;
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #d9d9d9;
    border: none;
    box-shadow: none;
    padding: 18px 25px 12px 25px;
    text-transform: capitalize;
    display: block;
    border-radius: 0px;
  }

  &.css-toe7b3-MuiButtonBase-root-MuiButton-root:hover {
    background-color: #3c735c;
  }

  &:focus {
    background-color: #3c735c;
  }
}

.user-roles-wrapper {
  border: 2px solid #d9d9d9;
  display: inline-block;
  width: 325px;

  &.css-1hn5dus-MuiPaper-root {
    border-radius: 5px;
    background-color: transparent;
  }
}

.addNewCategoryDialog.user-role-dialog .MuiDialog-paper {
  min-width: 530px !important;
  min-height: 575px;
}

.addNewCategoryDialog .user-role-actions {
  justify-content: space-around;
}

.MuiPaper-root {
  .MuiDialogActions-root {
    height: 40px;
    padding-bottom: 10px;
    justify-content: end;
    gap: 20px;
    margin-top: 15px;
    padding: 0;

    button {
      margin: 0;
      border-color: #ffffff;
      color: #ffffff;
      &.Mui-disabled {
        color: #aaa;
        background-color: #727272;
      }
    }

    .SaveBtn {
      border-radius: 15px;
      box-shadow: none;
      height: 40px;
      min-width: 125px;
      padding: 12px;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: #ffffff;
      text-transform: capitalize;
      margin-right: 20px;
    }

    .ClsBtn {
      background: transparent;
      border: 2px solid #c66969;
      border-radius: 15px;
      box-shadow: none;
      height: 40px;
      min-width: 125px;
      padding: 12px;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: #c66969;
      text-transform: capitalize;

      &:hover {
        background-color: transparent;
        border: 2px solid #c66969;
      }
    }
  }

  .dialog-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0 20px;

    .modelCloseBtn {
      display: flex;
      position: unset;
    }
  }

  .w-100 {
    width: 95%;
  }
}

.custom-checkbox {
  position: relative;
  width: fit-content;
  margin-bottom: 10px;

  input {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    opacity: 0;
    cursor: pointer;
  }
  label {
    padding: 0 0 0 30px;
    &::before {
      position: absolute;
      content: "";
      left: 0;
      top: 50%;
      height: 18px;
      width: 18px;
      border: 1px solid #ccc;
      transform: translate(0, -50%);
      border-radius: 3px;
      background-color: transparent;
    }
    &:after {
      position: absolute;
      content: "";
      width: 12px;
      height: 6px;
      border: 2px solid #fff;
      border-top: none;
      border-right: none;
      transform: rotate(-45deg);
      top: 7px;
      left: 3px;
      display: none;
    }
  }
  input[type="checkbox"]:checked ~ label:before {
    background-color: #3c735c;
    border-color: #3c735c;
  }
  input[type="checkbox"]:checked ~ label:after {
    display: block;
  }
}

.addNewWheelDialog {
  .MuiPaper-root {
    max-width: 577px !important;
    height: 280px !important;
  }
}

.MuiDialogContent-root {
  .MuiPaper-root {
    .MuiTableBody-root {
      .MuiTableCell-root {
        .MuiFormControl-root {
          .MuiInputBase-root {
            color: #fff !important;
            margin: 0px !important;
            border: 0px !important;

            svg {
              display: none;
            }
          }

          .MuiInputBase-root::before {
            border-bottom: 1px solid #bebebe !important;
          }

          .MuiInputBase-root::after {
            border-bottom: 1px solid #ffffff !important;
          }
        }
      }

      .MuiTableRow-root {
        .MuiTableCell-root {
          .MuiButtonBase-root {
            .MuiSvgIcon-root {
              fill: #c7c5c5;
            }
          }
        }
      }
    }
  }
}

.MuiPaper-root {
  .MuiList-root {
    .MuiButtonBase-root {
      .MuiBox-root {
        .MuiListItemIcon-root {
          display: none !important;
        }
      }
    }
  }
}
