.duplicateZoneDialog {
  .MuiDialog-paper {
    max-width: 565px;
    background: #34393f;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.35);
    border-radius: 10px;
    padding: 35px !important;
  }
  &__inner {
    max-width: 400px;
    margin: 0 auto;
    width: 100%;
  }
  &-title {
    font-weight: 700 !important;
    font-size: 20px !important;
    line-height: 23px !important;
    color: #d9d9d9;
    margin: 0 0 38px !important;
  }
  .MuiDialogContent-root {
    padding: 0;
  }
  .MuiDialogActions-root {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 25px;
    button {
      margin: 0 !important;
      background: #3b5c84;
      border-radius: 15px;
      box-shadow: none;
      height: 40px;
      min-width: 125px;
      padding: 12px;
      border: 2px solid #3b5c84;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: #ffffff;
      text-transform: capitalize;
      &:last-child {
        background: transparent;
        border: 2px solid #fff;
      }
    }
  }
}
.modelCloseBtn {
  position: absolute;
  top: 25px;
  right: 25px;
  height: 25px;
  width: 25px;
  z-index: 11;
  cursor: pointer;
  color: #fff !important;
}
