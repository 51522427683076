.addChannelModelDialog {
  .MuiDialog-paper {
    max-width: 676px;
    background: #34393f;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.35);
    border-radius: 17px !important;
    padding: 35px !important;
  }
  &-title {
    font-weight: 700 !important;
    font-size: 20px !important;
    line-height: 23px !important;
    color: #d9d9d9;
    margin: 0 0 58px !important;
  }
  .MuiDialogActions-root {
    padding: 0;
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 25px;
    button {
      margin: 0 !important;
      background:#3C735C;
      border-radius: 15px;
      box-shadow: none;
      height: 40px;
      min-width: 125px;
      padding: 12px;
      border: 2px solid #3C735C;
      font-weight: 400;
      font-size: 14px;
      line-height: 2px;
      color: #ffffff;
      text-transform: capitalize;
      &:last-child {
        background: transparent;
        border: 2px solid #fff;
      }
    }
  }
}
.dialogForm {
  max-width: 500px;
  margin: 0 auto;
  width: 100%;
  &-field {
    display: grid;
    grid-template-columns: 130px auto;
    gap: 20px;
    align-items: center;
    margin-bottom: 20px;
    &-label {
      font-weight: 400 !important;
      font-size: 14px !important;
      line-height: 16px !important;
      color: #d9d9d9;
    }
  }
  .addModel-title {
    margin-bottom: 2rem;
  }
}
