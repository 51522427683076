.grayCard {
  border: 2px solid #d9d9d9;
  border-radius: 5px;
  &__head {
    background-color: rgb(217, 217, 217);
    padding: 10px 22px;
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    color: rgb(60, 67, 75);
  }
  &__body {
    padding: 18px 22px;
    .MuiCheckbox-root {
      padding: 4px !important;
    }
    .MuiCheckbox-root + .MuiTypography-root {
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: #3c434b;
    }
    .MuiSlider-root .MuiSlider-thumb {
      border-radius: 0;
      background: #0d7cd7;
      box-shadow: none;
      width: 12px;
      height: 18px;
      &::before {
        box-shadow: none;
        height: 8px;
        width: 8px;
        display: block;
        background: #0d7cd7;
        transform: rotate(45deg);
        position: absolute;
        bottom: -4px;
      }
    }
  }
}

.checkNumberBox {
  display: flex !important;
  flex-direction: row !important;
  flex-wrap: nowrap !important;
  gap: 5px;
  align-items: center;
  justify-content: space-between;
  .MuiCheckbox-root {
    padding: 4px !important;
  }
  .MuiTypography-root {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #3c434b;
  }
  &__input {
    display: flex;
    align-items: center;
    gap: 8px;
    .MuiTypography-root {
      white-space: pre;
    }
  }
}

.checkBoxGroup {
  width: 240px;
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 15px;
  align-items: center;
  padding: 8px 22px;
  .MuiCheckbox-root {
    padding: 4px !important;
  }
  .MuiTypography-root {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #3c434b;
  }
}
.sliderLine {
  .MuiSlider-root {
    color: #d9d9d9;
  }
}
