.customSearch {
    background-color: #e5e5e5;
    border: none !important;
    border-radius: 20px;
    .MuiInputBase-root {
        .MuiInputAdornment-root {
            margin: 0;
        }
        input {
            padding: 10px;
            line-height: 15px;
            font-size: 14px;
            font-weight: 700;
        }
        &:hover {
            box-shadow: unset;
            border: none;
            outline: 0;
        }
    }
    fieldset {
        border: none;
    }
}
.button-list{
    button{
        justify-content: flex-start;
    }
}