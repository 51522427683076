.ChannelhorizontalTabs {
  &__item {
    display: flex !important;
    align-items: center !important;
    flex-direction: revert !important;
    justify-content: space-between !important;
    position: relative !important;
    padding: 5px 50px 5px 15px !important;
    gap: 1px;
    height: 50px;
    min-height: 50px !important;
    box-sizing: border-box !important;
    &-icon {
      display: grid;
      gap: 2px;
      text-align: center;
      font-weight: 300;
      font-size: 12px;
      line-height: 14px;
      color: #ffffff;
      margin-top: -5px;
      opacity: 0;
      margin-bottom: 0 !important;
      transition: all 0.2s ease;
      svg {
        height: 18px !important;
        width: 18px !important;
        margin: 0 auto;
      }
    }
    &::before {
      content: "";
      height: 3px;
      width: 100px;
      border-radius: 5px;
      position: absolute;
      right: 17px;
      display: block;
      background-color: #fff;
      opacity: 0;
      transition: all 0.2s ease;
    }
    &::after {
      content: "";
      height: 12px;
      width: 12px;
      border-top: 3px solid #fff;
      border-right: 3px solid #fff;
      display: block;
      transform: rotate(45deg);
      border-radius: 2px;
      position: absolute;
      right: 16px;
      opacity: 0;
      transition: all 0.2s ease;
    }
    &.Mui-selected {
      .ChannelHorizontalTabs__item-icon {
        opacity: 0%;
      }
      &::after,
      &::before {
        opacity: 100%;
      }
      .CustomCheckBoxsidebar {
        span {
          border: 1px solid #fff;
        }
        input:checked ~ span {
          border: 1px solid #dce6f1;
          background-color: #dce6f1;
        }
      }
    }
    .MuiFormControlLabel-labelPlacementEnd {
      margin-left: 10px;
    }
    .ChannelCheckBoxsidebar {
      width: 300px;
      height: 300px;
      border-radius: 0 !important;
      margin-right: 6px !important;
      svg {
        display: none;
      }
      span {
        border: 1px solid #3c434b;
        border-radius: 5px;
      }
      input:checked ~ span {
        border: 1px solid #3c434b;
      }
      input:checked ~ span {
        &::after {
          content: "";
          position: absolute;
          width: 13px;
          height: 6px;
          border: 2.5px solid #3b5c84;
          border-right: none;
          border-top: none;
          top: 5px;
          left: 4px;
          transform: rotate(-52deg);
        }
      }
    }
  }
}
.channelListSidebarWrapper {
  height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.channelListSidebarWrapper::-webkit-scrollbar {
  width: 8px;
}

.channelListSidebarWrapper::-webkit-scrollbar-thumb {
  background-color: #9e9e9e;
  border-radius: 4px;
}

.channelListSidebarWrapper::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.sidebarHeader {
  display: flex;
}

.ChannelhorizontalTabs button {
  min-height: 75px;
}
.scrollableTabs {
  .MuiTabs-flexContainer.MuiTabs-flexContainerVertical {
    overflow-y: scroll;
    max-height: 650px;
  }
}
.scrollableTabs2 {
  overflow: hidden;
}
