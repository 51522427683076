.themeSwitch {
  padding: 10px;
  button {
    font-size: 12px;
    padding: 8px 12px;
    width: 100%;
    min-width: 0 !important;
    display: flex;
    align-items: center;
    gap: 10px;
    background: transparent;
    .MuiButton-startIcon {
      margin: 0 !important;
    }
  }
}
.sidebar {
  .MuiList-root.MuiList-padding {
    padding: 0;
  }
  .menuItem-Link {
    padding: 20px 25px;
    border-bottom: 1px solid #878787;
    .MuiListItemIcon-root {
      height: 32px !important;
      width: 32px !important;
      min-width: 32px !important;
      svg {
        height: 32px;
        width: 32px;
      }
    }
  }
  &--active {
    .menuItem-Link {
      border-color: transparent;
      .MuiListItemIcon-root {
        height: 30px !important;
        width: 30px !important;
        min-width: 30px !important;
        svg {
          height: 30px;
          width: 30px;
        }
      }
      .MuiListItemText-root {
        opacity: 0;
      }
    }
    .themeSwitch {
      button {
        font-size: 0;
        gap: 0;
      }
    }
  }
}

.sidebar {
  .MuiList-root.MuiList-padding {
    padding: 0;
  }
  .menuItem-Link {
    padding: 20px 25px;
    border-bottom: 1px solid #878787;
    .MuiListItemIcon-root {
      height: 32px !important;
      width: 32px !important;
      min-width: 32px !important;
      svg {
        height: 32px;
        width: 32px;
      }
    }
  }

  .menuItem-Link-select {
    // padding: 20px 25px;
    border-bottom: 1px solid #878787;
    background-color: #3c735c;
    &:hover {
      background-color: #3c735c;
    }
    span {
      font-size: 15px !important;
      line-height: 21px;
    }
    .MuiListItemIcon-root {
      height: 32px !important;
      width: 32px !important;
      // min-width: 32px !important;
      min-width: unset;
      svg {
        height: 32px;
        width: 32px;
      }
    }
  }

  &--active {
    .menuItem-Link {
      border-color: transparent;
      .MuiListItemIcon-root {
        height: 30px !important;
        width: 30px !important;
        min-width: 30px !important;
        svg {
          height: 30px;
          width: 30px;
        }
      }
      .MuiListItemText-root {
        opacity: 0;
      }
    }
    .menuItem-Link-select {
      span {
        opacity: 0;
      }
    }
    .themeSwitch {
      button {
        font-size: 0;
      }
    }
  }
}
.siteHeaderTop {
  width: 100% !important;
}
