.toolbarPanel {
  padding: 0 80px !important;
  box-sizing: content-box !important;

  .button-list {
    padding: 0 !important;
  }

  &__menu {
    padding: 0 !important;
    height: 90px;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    // color: #3c434b !important;
    font-size: 15px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: normal !important;
    gap: 6px;
    border-radius: 0 !important;
    margin: 0 !important;

    svg {
      height: 32px;
      width: 32px;
    }

    span {
      display: flex !important;
      align-items: center !important;

      svg {
        height: 20px;
        width: 20px;
      }
    }

    &:hover {
      background: #cbe4d3 !important;
      color: #3c434b !important;
    }
  }

  .customSearch {
    border-radius: 14px;
    background: #e8e8e8;

    input {
      color: #3c434b;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}

.toolbarPanel {
  padding: 0 80px !important;
  box-sizing: content-box !important;

  .button-list {
    padding: 0 !important;
  }

  &__menu {
    padding: 0 !important;
    height: 90px;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    font-size: 15px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: normal !important;
    gap: 6px;
    border-radius: 0 !important;
    margin: 0 !important;

    svg {
      height: 32px;
      width: 32px;
    }

    span {
      display: flex !important;
      align-items: center !important;

      svg {
        height: 20px;
        width: 20px;
      }
    }

    &:hover {
      background: #cbe4d3 !important;
      svg {
        fill: #000;
      }
    }
  }

  .customSearch {
    border-radius: 14px;
    background: #e8e8e8;

    input {
      color: #3c434b;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}

.MuiMenu-list {
  padding: 0 !important;
}

.MuiPaper-root {
  box-shadow: none !important;
  border-radius: 0 !important;
}

.toolbar-dropdownMenuList {
  background: #cbe4d3;
  border-radius: 5px;
  min-width: 275px;
  &:hover {
    background-color: #cbe4d3;
  }

  .MuiMenuItem-root {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 16px 22px;
    border-bottom: 1px solid #bcbcbc;
    color: #4e4728;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
  }

  & > div:last-child {
    .MuiMenuItem-root {
      border-bottom: none;
    }
  }
}

.dropdown {
  background: #cbe4d3 !important;
  min-width: 275px !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  padding: 16px 22px !important;
  border-bottom: 1px solid #bcbcbc !important;
  color: #284e3e !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: normal !important;
}

@media only screen and (max-width: 1240px) {
  .toolbarPanel {
    padding: 0 24px !important;
  }
}
.toolsDropdown {
  white-space: inherit !important;
}
.toolbarPanel__menu {
  max-width: 10rem;
  width: 100% !important;
}


