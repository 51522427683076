.textAreaDiv {
  display: flex;
  align-content: center;
  .textArea {
    background-color: transparent;
    text-align: center;
    margin-right:15px;
    border-radius: 8px;
   text-align: center;
  }
  ::placeholder { 
    color: rgb(255, 255, 255);
    padding: 7px 0px;
  }
  button {
    border-radius: 0;
  }
}
.musicLibraryTextField{
  display: flex;
  align-items: center;
  text-align: center;
  .textArea {
    background-color: transparent;
    text-align: center;
    margin-right:15px;
    border-radius: 8px;
   text-align: center;
  }
  ::placeholder { 
    color: rgb(255, 255, 255);
  }
} 

