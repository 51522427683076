.tabContentPage {
  padding: 28px 75px;
  .MuiPaper-root {
    padding: 0;
  }
}

.tabTablePage {
  table {
    th,
    td {
      padding: 12px 28px;
      height: 50px;
      color: #3c434b;
      font-size: 16px;
      font-weight: 400;
      line-height: normal;
      height: auto;
      .Mui-TableHeadCell-Content {
        height: 100%;
      }
    }
    tbody {
      tr {
        td {
          border-bottom: 1px solid #cfcfcf;
        }
      }
    }
  }
}
