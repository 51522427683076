.ImortAudioDialog {
  .MuiDialog-paper {
    max-width: 600px;
    background: #34393f;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.35);
    border-radius: 10px;
    padding: 35px 70px !important;
  }
  &__inner {
    max-width: 666px;
    margin: 0 auto;
    width: 100%;
  }
  &-title {
    font-weight: 700 !important;
    font-size: 20px !important;
    line-height: 23px !important;
    color: #d9d9d9;
    margin: 0 0 25px !important;
  }
  .MuiDialogContent-root {
    padding: 0;
  }
  .MuiDialogActions-root {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 25px;
    button {
      margin: 0 !important;
      background:#3C735C;
      border-radius: 15px;
      box-shadow: none;
      height: 40px;
      min-width: 125px;
      padding: 12px;
      border: 2px solid#3C735C;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: #ffffff;
      text-transform: capitalize;
      &:last-child {
        background: transparent;
        border: 2px solid #fff;
      }
    }
  }
}

.importfieldBox {
  border: 2px solid #d9d9d9;
  border-radius: 5px;
  margin-bottom: 20px;
  &__head {
    background: #d9d9d9;
    padding: 10px 25px;
    &-title {
      font-weight: 700 !important;
      font-size: 16px !important;
      line-height: 18px !important;
      color: #3c434b;
    }
  }
  &__body {
    display: flex;
    padding: 20px 25px;
    gap: 15px;
    &-field {
      width: 100%;
      display: grid;
      gap: 20px;
      align-items: center;
      // margin-bottom: 20px;
      &-label {
        font-weight: 400 !important;
        font-size: 14px !important;
        line-height: 16px !important;
        color: #d9d9d9;
      }
      .MuiInputBase-root:hover {
        fieldset {
          border-color: transparent;
        }
      }
      &.ImportField {
        .MuiInputBase-root {
          .MuiInputBase-input {
            background: transparent;
            color: #fff;
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            color: #d9d9d9;
            height: 32px;
            box-sizing: border-box;
            padding: 5px 10px;
          }
          fieldset {
            border: 2px solid #e8e8e8;
            border-radius: 9px;
            height: 38px;
          }
        }
      }
    }
    .selectScheduleTime {
      display: flex;
    }
    .MuiSelect-select {
      border: 2px solid #e8e8e8 !important;
      border-radius: 9px !important;
      height: 34px !important;
      box-sizing: border-box !important;
      padding: 8px 20px !important;
      font-weight: 400 !important;
      font-size: 14px !important;
      line-height: 16px !important;
      color: #d9d9d9 !important;
      z-index: 1;
      position: relative;
    }
    .selectBox {
      max-width: 600px;
      width: 100%;
      .MuiSelect-select {
        outline: none;
        border: none !important;
      }
      fieldset {
        border-color: #e8e8e8 !important;
        border-radius: 10px;
      }
    }
  }
}
.AudioCustomCheckBox {
  margin-bottom: 20px;
  label {
    margin: 0;
    span {
      padding: 0;
      padding-right: 10px;
    }
  }
}

.uploadBtn {
  padding: 2px 8px !important;
  min-width: unset !important;
  background-color:#3C735C !important;
  border-radius: 9px !important;
  svg {
    width: 23px;
  }
}

@media only screen and (max-width: 1440px) {
  .ImortAudioDialog {
    .MuiDialog-paper {
      padding: 30px !important;
    }
  }
}
