.horizontalTabs {
  &__item {
    display: flex !important;
    align-items: center !important;
    flex-direction: revert !important;
    justify-content: center !important;
    position: relative !important;
    padding: 5px 50px 5px 15px !important;
    gap: 1px;
    height: 50px;
    min-height: 50px !important;
    box-sizing: border-box !important;
    &-icon {
      display: grid;
      gap: 2px;
      text-align: center;
      font-weight: 300;
      font-size: 12px;
      line-height: 14px;
      color: #ffffff;
      margin-top: -5px;
      opacity: 0;
      margin-bottom: 0 !important;
      transition: all 0.2s ease;
      svg {
        height: 18px !important;
        width: 18px !important;
        margin: 0 auto;
      }
    }
    &::before {
      content: "";
      height: 3px;
      width: 20px;
      border-radius: 5px;
      position: absolute;
      right: 17px;
      display: block;
      background-color: #fff;
      opacity: 0;
      transition: all 0.2s ease;
    }
    &::after {
      content: "";
      height: 12px;
      width: 12px;
      border-top: 3px solid #fff;
      border-right: 3px solid #fff;
      display: block;
      transform: rotate(45deg);
      border-radius: 2px;
      position: absolute;
      right: 16px;
      opacity: 0;
      transition: all 0.2s ease;
    }
    &.Mui-selected {
      background-color: #cc0000;
      .horizontalTabs__item-icon {
        opacity: 100%;
      }
      &::after,
      &::before {
        opacity: 100%;
      }
      .CustomCheckBox {
        span {
          border: 1px solid #fff;
        }
        input:checked ~ span {
          border: 1px solid #dce6f1;
          background-color: #dce6f1;
        }
      }
    }
    .MuiFormControlLabel-labelPlacementEnd {
      margin-left: 10px;
    }
    .CustomCheckBox {
      width: 23px;
      height: 23px;
      border-radius: 0 !important;
      margin-right: 6px !important;
      svg {
        display: none;
      }
      span {
        border: 1px solid #3c434b;
        border-radius: 5px;
      }
      input:checked ~ span {
        border: 1px solid #3c434b;
      }
      input:checked ~ span {
        &::after {
          content: "";
          position: absolute;
          width: 13px;
          height: 6px;
          border: 2.5px solid #42876a;
          border-right: none;
          border-top: none;
          top: 5px;
          left: 4px;
          transform: rotate(-52deg);
        }
      }
    }
  }
}
.listItem {
  span {
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
  }
  &.active {
    color: #fff;
    background: #42876a;
    svg {
      background-color: #dcdcdc;
      border-radius: 100%;
      color: #42876a;
    }
  }
  &:hover {
    color: #fff !important;
    background: #42876a !important;
  }
}

.subItem {
  span {
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
  }
  &.active {
    color: #fff;
    background: #42876a;
  }
  &:hover {
    color: #fff !important;
    background: #42876a !important;
    // svg {
    //   display: none;
    // }
  }
}

.newCustomerBtn {
  font-weight: bold !important;
  font-size: 16px !important;
  line-height: 18px !important;
  text-transform: none !important;
  margin-top: 15px !important;
  border: 2px solid #3c735c !important;
  border-radius: 9px !important;
  max-width: 198px !important;
  width: 100% !important;
  gap: 5px;
  svg {
    width: 20px;
  }
}
.CustomerBtn {
  justify-content: center;
  gap: 40px;
  button {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #d9d9d9;
    padding: 0;
    height: auto;
    width: 100%;
    max-width: 65px;
    &.Mui-disabled {
      svg {
        border: 1px solid #a5a8ac9c;
        color: #a5a8ac9c;
      }
      h6 {
        color: #a5a8ac9c;
      }
    }
    .MuiSvgIcon-root {
      border: 1px solid #fff;
      font-size: 1rem;
      color: #fff;
    }

    &:first-child {
      max-width: 88px;
      // margin-right: 25px;
    }
    h6 {
      white-space: nowrap;
      margin: 0;
      margin-top: 3px;
      line-height: 16px;
      color: #d9d9d9;
    }
    span {
      display: none;
    }
  }
}

.ChannelHorizontalTabs__item {
  span,
  div {
    display: none;
    justify-content: start !important;
  }
}

.ChannelHorizontalTabs__item.Mui-selected {
  span,
  div {
    display: block;
    justify-content: space-between !important;
  }
}

.brd_left {
  position: relative;
}
.brd_left:before {
  content: "";
  position: absolute;
  right: 0;
  width: 100%;

}
