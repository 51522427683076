.addChannelSongDialog {
  .MuiDialog-paper {
    max-width: 1035px;
    background: #34393f;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.35);
    border-radius: 10px;
    padding: 35px !important;
  }
  &-title {
    font-weight: 700 !important;
    font-size: 20px !important;
    line-height: 23px !important;
    color: #d9d9d9;
    margin: 0 0 58px !important;
  }
  .MuiDialogActions-root {
    padding: 0;
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 25px;
    button {
      margin: 0 !important;
      background: #3b5c84;
      border-radius: 15px;
      box-shadow: none;
      height: 40px;
      min-width: 125px;
      padding: 12px;
      border: 2px solid #3b5c84;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: #ffffff;
      text-transform: capitalize;
      &:last-child {
        background: transparent;
        border: 2px solid #fff;
      }
    }
  }
}
.SongDialogForm {
  max-width: 848px;
  margin: 0 auto;
  width: 100%;
  &-field {
    display: grid;
    grid-template-columns: 130px auto;
    gap: 20px;
    align-items: center;
    margin-bottom: 20px;
    &-label {
      font-weight: 400 !important;
      font-size: 14px !important;
      line-height: 16px !important;
      color: #d9d9d9;
    }
  }
  .addModel-title {
    margin-bottom: 2rem;
  }
}

.modelTable {
  border: 2px solid #4f4f4f;
  border-radius: 5px;
  overflow: hidden;
  .MuiTablePagination-root {
    color: #fff !important;
    svg {
      fill: #696969;
    }
  }
  table {
    td {
      border-color: #585858;
      background-color: #34393f;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: #d9d9d9;
      padding: 10px 20px;
      text-align: left;
      .MuiCheckbox-root {
        padding: 0 !important;
        svg {
          fill: #ffffff;
        }
      }
    }
    thead {
      background: #676767;
      th {
        align-items: center;
        font-weight: 400;
        font-size: 16px;
        padding-left: 22px;
        line-height: 30px;
        color: #000000;
        &:not(:last-child) {
          border-right: 1px solid #aaaaaa;
        }
        .MuiTableSortLabel-root {
          display: block;
          svg {
            position: absolute;
            right: 0;
          }
        }
        .MuiCheckbox-root {
          padding: 0 !important;
          svg {
            fill: #3b5c84;
          }
        }
      }
    }
  }
}

.modelTableDark {
  border: 2px solid #4f4f4f;
  border-radius: 5px;
  overflow: hidden;
  .MuiTablePagination-root {
    color: #fff !important;
    svg {
      fill: #696969;
    }
  }
  table {
    td {
      border-color: #585858;
      background-color: #34393f;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: #d9d9d9;
      padding: 10px 20px;
      text-align: left;
      .MuiCheckbox-root {
        padding: 0 !important;
        svg {
          fill: #ffffff;
        }
      }
    }
    thead {
      background: #676767;
      th {
        align-items: center;
        font-weight: 400;
        font-size: 16px;
        padding-left: 22px;
        line-height: 30px;
        color: #fff !important;
        &:not(:last-child) {
          border-right: 1px solid #aaaaaa;
        }
        .MuiTableSortLabel-root {
          display: block;
          svg {
            position: absolute;
            right: 0;
          }
        }
        .MuiCheckbox-root {
          padding: 0 !important;
          svg {
            fill: #3b5c84;
          }
        }
      }
    }
  }
}

.modelSearchField {
  background: #272728;
  border: 2px solid #030303 !important;
  border-radius: 9px;
  margin-bottom: 35px !important;
  height: 32px;
  overflow: hidden;

  input {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    padding: 8px 30px;
    color: #d9d9d9;
    height: 30px;
    box-sizing: border-box;
  }
  .MuiInputBase-root:hover fieldset {
    border: none !important;
  }
  fieldset {
    height: 34px;
    border: none !important;
  }
  .MuiButton-root {
    text-transform: none;
    background-color: #d9d9d9;
    border: none !important;
    box-shadow: none !important;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: #3c434b;
    border-radius: 0;
    height: 30px;
    &:hover {
      background-color: #d9d9d9;
      color: #3c434b;
      border: none !important;
      box-shadow: none !important;
    }
  }
  .MuiInputBase-root {
    padding: 0 !important;
  }
}
.ChannelSongList {
  .MuiDataGrid-columnHeaders {
    .MuiDataGrid-columnHeadersInner {
      width: 100%;
      .css-yrdy0g-MuiDataGrid-columnHeaderRow {
        width: 100%;
        background: #d9d9d9;
        .MuiDataGrid-columnHeader {
          width: 24% !important;
          max-width: unset !important;
          border-left: 1px solid #aaaaaa;
          &:first-child {
            width: 4% !important;
            border: none;
          }
          .MuiDataGrid-iconSeparator {
            display: none;
          }
        }
      }
    }
  }
  .MuiDataGrid-virtualScroller {
    .MuiDataGrid-virtualScrollerRenderZone {
      width: 100%;
      .MuiDataGrid-row {
        width: 100%;
        .MuiDataGrid-cell {
          width: 24% !important;
          max-width: unset !important;
          border-color:#585858;
          &:first-child {
            width: 4% !important;
            svg {
              color: #d9d9d9;
            }
          }
          &:last-child {
            display: none;
          }
        }
      }
    }
  }
  .MuiDataGrid-cellContent {
    color: #d9d9d9;
  }
  .MuiToolbar-root-MuiTablePagination-toolbar {
    color: #d9d9d9;
    svg {
      color: #d9d9d9;
    }
  }
  .MuiDataGrid-selectedRowCount{
    color: #d9d9d9;
  }
}
