.addModelDialog {
  .MuiDialog-paper {
    background: #34393f;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.35);
    border-radius: 10px;
    padding: 35px !important;
  }

  &-title {
    font-weight: 700 !important;
    font-size: 20px !important;
    line-height: 23px !important;
    color: #d9d9d9;
    margin: 0 0 58px !important;
  }

  .MuiDialogActions-root {
    padding: 0;
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 25px;

    button {
      margin: 0 !important;
      background: #3b5c84;
      border-radius: 15px;
      box-shadow: none;
      height: 40px;
      min-width: 125px;
      padding: 12px;
      border: 2px solid #3b5c84;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: #ffffff;
      text-transform: capitalize;

      &:last-child {
        background: transparent;
        border: 2px solid #fff;
      }
    }
  }
}

.dialogForm {
  max-width: 500px;
  margin: 0 auto;
  width: 100%;

  &-field {
    display: grid;
    grid-template-columns: 130px auto;
    gap: 20px;
    align-items: center;
    margin-bottom: 20px;

    &-label {
      font-weight: 400 !important;
      font-size: 14px !important;
      line-height: 16px !important;
      color: #d9d9d9;
    }

    .MuiSelect-select {
      border: 2px solid #e8e8e8 !important;
      border-radius: 9px !important;
      height: 34px !important;
      box-sizing: border-box !important;
      padding: 8px 0px !important;
      font-weight: 400 !important;
      font-size: 14px !important;
      line-height: 16px !important;
      color: #d9d9d9 !important;
    }

    svg {
      color: #fff !important;
    }

    .TimePicker {
      color: white
    }

    .TimePicker {
      .MuiInputBase-formControl {
        height: 34px;
        font-size: 14px !important;

        button {
          svg {
            width: 25px;
            display: block;
            color: #ffff;
          }
        }

        fieldset {
          border: 2px solid #e8e8e8 !important;
          border-radius: 9px !important;
          height: 38px !important;
          box-sizing: border-box !important;
          padding: 8px 20px !important;
          font-weight: 400 !important;
          font-size: 14px !important;
          line-height: 16px !important;
          color: #d9d9d9 !important;
        }

        label {
          display: none;
        }

        input {
          padding: 0 15px;
          color: #fff;


          &::placeholder {
            color: #fff;
          }
        }
      }
    }
  }

  .dialogForm-field-2 {
    .MuiSelect-select {
      height: auto !important;
      white-space: break-spaces;
    }

    .CustomDrpSelect {
      height: 36px;

      fieldset {
        border: 0px !important;
        height: auto !important;
      }
    }
  }
}

.CustomDrpSelect {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    // background-image: url(../../../assets/images/DrpDown.svg);
    // width: 15px;
    // height: 10px;
    background-repeat: no-repeat;
    // right: 10px;
    // top: 13px;
  }

  .MuiSelect-icon {
    color: #fff !important;
  }
}