.tabs-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow-x: auto;
  .btn-tab-back {
    display: flex;
    align-items: center;
    a {
      color: #b4b4b4;
      display: flex;
      align-items: center;
      text-decoration: none;
      gap: 5px;
      padding: 12px 16px;
    }
    .Audio-Tabs {
      min-height: unset;
      .audio-tab {
        font-weight: 400;
        font-size: 15px;
        line-height: 18px;
        height: auto;
        min-height: unset;
        border: none !important;
        padding: 16px 25px;
        max-width: fit-content;
        text-transform: capitalize;
        border-left: 1px solid #cfcfcf !important;
        white-space: nowrap;
        svg,
        .MuiTouchRipple-root {
          display: none;
        }
        &.Mui-selected {
          background-color: #3c735c;
          color: #fff;
        }
        &:last-child {
          border-right: 1px solid #cfcfcf !important;
        }
      }
    }
    .audio-tab {
      font-weight: 400;
      font-size: 15px;
      line-height: 18px;
      height: auto;
      min-height: unset;
      border: none !important;
      padding: 12px 25px;
      max-width: fit-content;
      text-transform: capitalize;
      color: #fff;
    }
  }
  .btn-tab {
    margin-right: 20px;
    button {
      background: #3c735c;
      font-weight: 500;
      font-size: 16px;
      color: #ffffff;
      padding: 15px 25px;
      border-radius: 0;
      line-height: normal;
      text-transform: capitalize;
      height: 50px;
    }
  }
}
.MuiSlider-root .MuiSlider-thumb {
  border-radius: 0;
  background: #356caa;
  box-shadow: none;
  width: 12px;
  height: 18px;
  &::before {
    box-shadow: none;
    height: 8px;
    width: 8px;
    display: block;
    background: #356caa;
    transform: rotate(45deg);
    position: absolute;
    bottom: -4px;
  }
}

.AudioTrackTable {
  .MuiTableHead-root {
    th {
      background: #e8e8e8;
    }
  }
}
.SongDataEditor {
  .MuiAccordionDetails-root {
    max-width: 545px;
    width: 100%;
    .MuiInputBase-colorPrimary {
      background: #e0e0e0;
      border-radius: 9px;
      border: none;
      height: 34px;
      color: #3c434b;
      input {
        width: 100%;
        padding: 6px 15px;
        &:hover {
          border: none;
          outline: none;
        }
      }
      .MuiInputAdornment-positionEnd {
        background: transparent;
        padding-right: 13px;
        button {
          position: relative;
          svg {
            opacity: 0;
          }
        }
      }
    }
    .MuiInputBase-formControl {
      height: 34px;
      fieldset {
        border: none;
      }
    }
    .MuiInputAdornment-root {
      padding: 0 !important;
      margin: 0 !important;
      .MuiButtonBase-root {
        width: auto;
        padding: 0;
      }
    }
    .DrpCustom {
      .MuiInputBase-colorPrimary {
        position: relative;
        &::before {
          content: "";
          position: absolute;
          top: 15px;
          right: 10px;
          width: 12px;
          height: 8px;
          background-image: url("../../assets/images/downarrow.svg");
          background-repeat: no-repeat;
          background-size: 100%;
        }
      }
    }
    .MuiFormControlLabel-labelPlacementEnd {
      margin: 0;
      span {
        padding-left: 0;
      }
    }
    h6 {
      font-weight: 500;
      font-size: 16px !important;
      line-height: 18px;
      // color: #3c434b;
    }
  }
}
.AccordionTitle {
  min-height: unset !important;
  .Mui-expanded {
    margin: 0 !important;
    padding: 10px 0;
    h5 {
      font-weight: 700 !important;
      font-size: 16px !important;
      line-height: 20px !important;
    }
  }
}
.TimeOfDay {
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  width: 100% !important;
  margin: 0 !important;
  gap: 15px;
  .MuiFormControl-root {
    flex-direction: row;
    align-items: center;
    .MuiFormLabel-root {
      position: unset;
      width: 100%;
      max-width: 50px;
      transform: unset;
      font-weight: 400;
      font-size: 16px;
      line-height: 18px;
      color: #3c434b;
    }
    .MuiOutlinedInput-root {
      position: relative;
      padding: 0;
      max-width: 65px;
      margin-left: 5px;
      input {
        background: #e0e0e0;
        border-radius: 9px;
        padding: 6px;
        text-align: center;
      }
      .MuiInputAdornment-positionEnd {
        position: absolute;
        right: 0;
        opacity: 0;
        width: 100%;
      }
      fieldset {
        border: none;
      }
    }
  }
  span {
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: #3c434b;
  }
}
.WeekDay {
  .MuiFormControlLabel-labelPlacementBottom {
    margin: 0;
  }
}
.AudioFileUpload {
  display: flex;
  align-items: center;
  gap: 15px;
  p {
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 18px !important;
    // color: #3c434b !important;
  }
  .MuiFormControl-root {
    max-width: 320px;
    .MuiInputBase-input {
      border: none;
      padding: 6px 15px;
      background: #e0e0e0;
      border-radius: 9px;
      color: #3c434b;
    }
  }
  fieldset {
    border: none !important;
  }
  .MuiButton-root {
    background: #3c735c;
    border-radius: 9px;
    max-width: 46px;
    padding: 5px 10px;
    width: 100%;
    min-width: unset;
    position: relative;
    cursor: pointer;
    box-shadow: none;
    height: 34px;
    input {
      opacity: 0;
      position: absolute;
      width: 100%;
      height: 100%;
      cursor: pointer;
    }
    svg {
      height: 22px !important;
    }
  }
}
.PlayBtn {
  background: #e0e0e0;
  border-radius: 9px;
  padding: 9px 30px;
  text-align: center;
  .BtnPart {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    button {
      border-radius: 0;
      padding: 8px;
      max-width: 65px;
      width: 100%;
      display: flex;
      align-items: center;
      &:first-child {
        background-color: #3c735c;
        border-top-left-radius: 9px;
        border-bottom-left-radius: 9px;
      }
      &:last-child {
        background-color: #a7acb1;
        border-top-right-radius: 9px;
        border-bottom-right-radius: 9px;
      }
    }
  }
  .DrpCustom {
    .MuiInputBase-input {
      padding: 5px 15px;
      border: 2px solid #a2a2a2;
      border-radius: 9px;
      position: relative;
      color: #000000;
      &::before {
        content: "";
        position: absolute;
        top: 15px;
        right: 10px;
        width: 12px;
        height: 8px;
        background-image: url("../../assets/images/downarrow.svg");
        background-repeat: no-repeat;
        background-size: 100%;
      }
    }
    svg {
      display: none;
    }
    fieldset {
      border: none;
    }
  }

  .VolumeSlider {
    .MuiSlider-rail {
      background: #fff;
      opacity: 1;
    }
  }
}
.SetTime {
  .MuiInputBase-root {
    background: #e0e0e0;
    border-radius: 9px;
    color: #3c434b;
  }
  fieldset {
    border: none;
  }
  button {
    background: #3c735c;
    border-radius: 15px;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: #ffffff;
    padding: 11px;
  }
}
.plyalistTable {
  .MuiTableHead-root {
    th {
      background: #e8e8e8;
      color: #000000 !important;
    }
  }
}

.ChannelAssTable {
  .MuiTableHead-root {
    th {
      background: #e8e8e8;
      color: #000000 !important;
    }
  }
}
.HistoryTable {
  .MuiTableHead-root {
    th {
      background: #e8e8e8;
      color: #000000 !important;
    }
  }
}
.categoryList {
  .MuiButtonBase-root.MuiListItemButton-root:hover {
    background-color: #e3eae4;
  }
}
.songHistoryTable {
  .MuiTableHead-root {
    th {
      background: #e8e8e8;
      color: #000000 !important;
    }
  }
}

.atributeList {
  max-height: 500px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 7px;
  }
  &::-webkit-scrollbar-track {
    background: #d9d9d9;
    border-radius: 3px;
  }
  &::-webkit-scrollbar-thumb {
    background: #afb2b6;
    box-shadow: inset 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 1px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #afb2b6;
    box-shadow: inset 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 1px;
  }
  .MuiButtonBase-root {
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: #3c434b;
    &:nth-child(even) {
      background-color: #e3eae4 !important;
    }
  }
  .MuiTypography-root {
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 18px !important;
  }
  .MuiButtonBase-root.atributeSelected {
    background-color: #3c735c !important;
    color: #fff;
  }
}

.tabsPage {
  .MuiAccordion-root {
    border: 1px solid #d9d9d9;
    border-radius: 5px !important;
    overflow: hidden;
    box-shadow: none;
    & > .MuiAccordionSummary-root {
      background: #d9d9d9;
      border-radius: 5px !important;
      padding: 8px 25px;
      min-height: 42px !important;
      & > div {
        padding: 0 !important;
      }
      .MuiAccordionSummary-content {
        margin: 0 !important;
      }
      .MuiTypography-root {
        font-weight: 700;
        font-size: 18px;
        line-height: 21px;
        // color: #3c434b;
      }
    }
  }
  &--characteristics {
    .MuiAccordion-root {
      .MuiAccordionSummary-root {
        border-radius: 0 !important;
        min-height: 50px !important;
      }
    }
  }
}

.dayCheckBoxGroup2 {
  display: grid !important;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  gap: 40px;
  width: 100% !important;
  text-align: center;
  margin-left: 0 !important;
  .MuiFormControlLabel-root {
    display: block;
    margin: 0;
    .MuiCheckbox-root {
      padding: 0 !important;
      margin-bottom: 8px;
    }
    span {
      font-weight: 400 !important;
      font-size: 16px !important;
      line-height: 18px !important;
      color: #d9d9d9;
    }
  }
  &--dark {
    .MuiFormControlLabel-root {
      .MuiCheckbox-root {
        svg path {
          fill: #3c434b;
        }
      }
      span {
        color: #3c434b;
      }
    }
  }
}
.userField-Btn {
  margin-top: 1rem;
  text-transform: none !important;
  border-radius: 14px !important;
}
@media only screen and (max-width: 1440px) {
  .dayCheckBoxGroup2 {
    gap: 16px;
    .MuiFormControlLabel-root {
      span {
        font-size: 14px !important;
        line-height: 16px !important;
      }
    }
  }
}

.addAttbtn {
  border: 1px solid #3c735c;
  color: #3c735c;
  border-radius: 10px;
  margin: "5px";
  :hover {
    background: #3c735c;
    color: white;
  }
}

.DrpCustom-btn {
  .MuiSelect-select {
    width: 150px;
    h5 {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
}
.Mui-expanded {
  margin: 0 !important;
}
.characteristicsAddModel {
  .MuiPaper-root {
    background-color: rgb(52, 57, 63);
    color: rgb(217, 217, 217);
    padding: 1rem;
    transform: translate(0px, 0px);
    .dialog-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 0 20px;
      h5 {
        color: #fff;
      }
    }
  }
}
.MuiPopover-paper {
  max-width: 300px !important;
  min-width: unset !important;
}
