.ChannelhorizontalTabs {
  &__item {
    display: flex !important;
    align-items: center !important;
    flex-direction: revert !important;
    justify-content: space-between !important;
    position: relative !important;
    padding: 5px 50px 5px 15px !important;
    gap: 1px;
    // height: 50px;
    height: auto;
    min-height: 50px !important;
    box-sizing: border-box !important;
    &-icon {
      display: grid;
      gap: 2px;
      text-align: center;
      font-weight: 300;
      font-size: 12px;
      line-height: 14px;
      color: #ffffff;
      margin-top: -5px;
      opacity: 0;
      margin-bottom: 0 !important;
      transition: all 0.2s ease;
      svg {
        height: 18px !important;
        width: 18px !important;
        margin: 0 auto;
      }
    }
    &::before {
      content: "";
      height: 3px;
      width: 100px;
      border-radius: 5px;
      position: absolute;
      right: 17px;
      display: block;
      background-color: #fff;
      opacity: 0;
      transition: all 0.2s ease;
    }
    &::after {
      content: "";
      height: 12px;
      width: 12px;
      border-top: 3px solid #fff;
      border-right: 3px solid #fff;
      display: block;
      transform: rotate(45deg);
      border-radius: 2px;
      position: absolute;
      right: 16px;
      opacity: 0;
      transition: all 0.2s ease;
    }
    &.Mui-selected {
      .ChannelHorizontalTabs__item-icon {
        opacity: 0%;
      }
      &::after,
      &::before {
        opacity: 100%;
      }
      .CustomCheckBoxsidebar {
        span {
          border: 1px solid #fff;
        }
        input:checked ~ span {
          border: 1px solid #dce6f1;
          background-color: #dce6f1;
        }
      }
    }
    .MuiFormControlLabel-labelPlacementEnd {
      margin-left: 10px;
    }
    .ChannelCheckBoxsidebar {
      width: 300px;
      height: 300px;
      border-radius: 0 !important;
      margin-right: 6px !important;
      svg {
        display: none;
      }
      span {
        border: 1px solid #3c434b;
        border-radius: 5px;
      }
      input:checked ~ span {
        border: 1px solid #3c434b;
      }
      input:checked ~ span {
        &::after {
          content: "";
          position: absolute;
          width: 13px;
          height: 6px;
          border: 2.5px solid #3b5c84;
          border-right: none;
          border-top: none;
          top: 5px;
          left: 4px;
          transform: rotate(-52deg);
        }
      }
    }
  }
}

.channelsonglist {
  table {
    th,
    td {
      color: #000000;
      font-size: 14px;
      font-weight: 400;
      line-height: normal;
      padding: 10px 22px;
      height: 50px;
      border-bottom: none;
      border-right: 1px solid #cfcfcf;
      height: auto;
      .Mui-TableHeadCell-Content {
        height: 100%;
        justify-content: space-between;
      }
      &:first-child {
        border-right: none;
      }
      &:last-child {
        border-right: none;
      }
    }
    thead {
      tr {
        background-color: rgb(224, 224, 224);
        box-shadow: none;
      }
    }
    tbody {
      tr {
        td {
          border-bottom: 1px solid #cfcfcf;
        }
      }
    }
  }
}

.channelsonglistDark {
  table {
    th,
    td {
      color: #ffffff;
      font-size: 14px;
      font-weight: 400;
      line-height: normal;
      padding: 10px 22px;
      height: 50px;
      border-bottom: none;
      border-right: 1px solid #cfcfcf;
      height: auto;
      .Mui-TableHeadCell-Content {
        height: 100%;
        justify-content: space-between;
      }
      &:first-child {
        border-right: none;
      }
      &:last-child {
        border-right: none;
      }
    }
    thead {
      tr, th {
        background-color: #000;
        box-shadow: none;
        // color: #000000 !important;
      }
    }
    tbody {
      tr {
        td {
          border-bottom: 1px solid #cfcfcf;
        }
      }
    }
  }
}

.sidebarHeader {
  display: flex;
}
