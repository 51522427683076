.addModelDialog {
  .MuiDialog-paper {
    background: #34393f;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.35);
    border-radius: 10px;
    padding: 35px !important;
  }

  &-title {
    font-weight: 700 !important;
    font-size: 20px !important;
    line-height: 23px !important;
    color: #d9d9d9;
    margin: 0 0 58px !important;
  }

  .MuiDialogActions-root {
    padding: 0;
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 25px;

    button {
      margin: 0 !important;
      background: #3c735c;
      border-radius: 15px;
      box-shadow: none;
      height: 40px;
      min-width: 125px;
      padding: 12px;
      border: 2px solid #3c735c;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: #ffffff;
      text-transform: capitalize;

      &:last-child {
        min-height: 20px;
        background: transparent;
        border: 2px solid #fff;
      }
    }
  }
}

.dialogForm {
  max-width: 500px;
  margin: 0 auto;
  width: 100%;

  &-field {
    display: grid;
    grid-template-columns: 130px auto;
    gap: 20px;
    align-items: center;
    margin-bottom: 20px;

    &-label {
      font-weight: 400 !important;
      font-size: 14px !important;
      line-height: 16px !important;
      color: #d9d9d9;
    }

    .MuiSelect-select {
      border: 2px solid #e8e8e816 !important;
      border-radius: 9px !important;
      height: 34px !important;
      box-sizing: border-box !important;
      padding: 8px 20px !important;
      font-weight: 400 !important;
      font-size: 14px !important;
      line-height: 16px !important;
      color: #d9d9d9 !important;
    }

    svg {
      color: #fff;
      // display: none;
    }

    .TimePicker {
      color: white
    }

    .TimePicker {
      .MuiInputBase-formControl {
        height: 34px;
        font-size: 14px !important;

        button {
          svg {
            display: block;
            color: #ffff;
          }
        }

        fieldset {
          border: 2px solid #e8e8e8 !important;
          border-radius: 9px !important;
          height: 38px !important;
          box-sizing: border-box !important;
          padding: 8px 20px !important;
          font-weight: 400 !important;
          font-size: 14px !important;
          line-height: 16px !important;
          color: #d9d9d9 !important;
        }

        label {
          display: none;
        }

        input {
          padding: 0 15px;
          color: #fff;

          &::placeholder {
            color: #fff;
          }
        }
      }
    }

    .CustomDrpSelect {
      fieldset {
        border: 2px solid #e8e8e8 !important;
        border-radius: 9px !important;
        height: 38px !important;
        box-sizing: border-box !important;
        padding: 8px 20px !important;
        font-weight: 400 !important;
        font-size: 14px !important;
        line-height: 20px !important;
        color: #d9d9d9 !important;
      }

      input {
        padding: 0 15px;
        color: #fff;

        &::placeholder {
          color: #fff;
        }
      }
    }
  }
}

.modal-top-dropin-actions {
  gap: 30px;
  margin: 20px 0;

  button {
    width: auto;
    padding: 0;
    aspect-ratio: unset;

    svg path {
      fill: #fff;
    }

    h6 {
      margin: 0;
      font-size: 14px;
      line-height: normal;
      color: #fff !important;
    }
  }
}

.dialogTable {
  .MuiTable-root {
    tbody {
      tr {
        &:hover {
          background-color: #3c735c !important;

          td {
            background-color: transparent !important;
          }
        }
      }
    }
  }
}