.btn-action-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 8px;
  flex-wrap: wrap;
  .action-btn {
    background-color: #3c735c !important;
    color: #e8e8e8 !important;
    border-radius: 9px;
    margin-top: 20px;
    padding: 9px 28px;
    text-transform: capitalize;
  }
}

.action-btn-export {
  background-color: transparent !important;
  color: #3c735c !important;
  border: 1px solid #3c735c !important;
  border-radius: 9px;
  margin-top: 20px !important;
  text-transform: capitalize;
}
.siteDataTab {
  padding: 55px;
  h6 {
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 18px !important;
  }
  .CustonSelect {
    .MuiSelect-select {
      padding: 7px 10px;
      display: flex;
      gap: 1rem;
    }
  }
  .CustonSelectDark {
    // max-width: 188px;
    .MuiSelect-select {
      padding: 7px 10px;
      color: #fff !important;
      display: flex;
      gap: 1rem;
    }
  }

  input {
    // background: #e0e0e0;
    border-radius: 9px;
    padding: 7px 10px;
    line-height: normal;
  }
}
.ViewSongs {
  thead {
    tr {
      background-color: #e8e8e8;
     th{
      .Mui-TableHeadCell-Content{
        justify-content: center;
      }
     }
    }
  }
    table {
    border-collapse: collapse;
    th,
    td {
      border: 1px solid #cfcfcf !important;
      text-align: center;
      color: #3c434b !important;
    }
    .cellColor {
      background: #f4dbdb;
    }
  }
}
.songHistory {
  display: flex;
  padding: 7px 50px;
  gap: 80px;
  > div {
    display: flex;
    align-items: center;
    gap: 20px;
    h6 {
      font-weight: 500 !important;
      font-size: 16px !important;
      line-height: 18px !important;
      color: #e8e8e8 !important;
    }
    .MuiOutlinedInput-root {
      border: 1.5px solid #e8e8e8;
      border-radius: 9px;
      padding: 0;
      input {
        padding: 6px 15px;
        color: #e8e8e8;
      }
      button {
        background-image: url("../../assets/images/DownArrowIcon.svg");
        background-repeat: no-repeat;
        padding: 0;
        height: 10px;
        width: 13px;
        position: absolute;
        right: 23px;
        svg {
          display: none;
        }
      }
    }
  }
}
.playedSongTable  {
  table {
    border-collapse: collapse;
    th,
    td {
      border: 1px solid #cfcfcf !important;
      text-align: center;
    }
    .cellColor {
      background: #f4dbdb;
    }
  }
}
.playSong {
  .music-library {
    display: flex;
    padding: 7px 50px;
    gap: 80px;
    > div {
      display: flex;
      align-items: center;
      gap: 20px;
      .MuiOutlinedInput-root {
        border: 1.5px solid #3c434b;
        border-radius: 9px;
        padding: 0;
        input {
          padding: 6px 15px;
        }
        button {
          background-image: url("../../assets/images/downarrow.svg");
          background-repeat: no-repeat;
          padding: 0;
          height: 10px;
          width: 13px;
          position: absolute;
          right: 23px;
          svg {
            display: none;
          }
        }
      }
    }
  }
  thead {
    tr {
      background-color: #e8e8e8;
     th{
      .Mui-TableHeadCell-Content{
        justify-content: center;
      }
     }
    }
  }
  table {
    border-collapse: collapse;
    th,
    td {
      border: 1px solid #cfcfcf !important;
      text-align: center;
      color: #3c434b !important;
    }
  }
}

.selectRow tr.MuiTableRow-root.Mui-selected {
  background-color: #42876a !important;
  color: #e8e8e8 !important;
}

.selectRow tr.MuiTableRow-root.Mui-selected:hover {
  background-color: #42876a !important;
  color: #e8e8e8 !important;
}


.selectRow tr.MuiTableRow-root.Mui-selected td {
  color: #e8e8e8;
}

.selectRow tr.MuiTableRow-root.Mui-selected td button {
  background-color: #e8e8e8;
  color: #42876a;
}

.CustomSelect {
  position: relative;
  // width: 104%;
  &::before {
    content: "";
    position: absolute;
    background-image: url("../../assets/images/downarrow.svg");
    width: 15px;
    height: 10px;
    background-repeat: no-repeat;
    right: 10px;
    top: 15px;
  }
  .MuiSelect-icon {
    display: none;
  }
  .MuiMenuItem-root {
    display: flex;
    justify-content: space-between;
  }
}
.menu-item-header {
  width: 100% !important;
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  padding: 0rem 1rem 0 1rem;
}

.menu-item-header span:first-child {
  margin-right: 4px;
}

.disableCustomerSite {
  background-color: #0d324d;
  opacity: 0.5 !important;
  pointer-events: none !important;
}
.sidebarLeft {
  width: 370px;
  min-height: calc(100vh - 178px);
}
.MainPartRight {
  width: calc(100% - 370px);
}

ul.MuiMenu-list[aria-labelledby="regionIdList"] {
  padding: 0;
  li {
    padding: 0;
    border-bottom: 1px solid #ccc;
    width: 301px !important;
    .menuItemId {
      width: 45%;
      padding: 5px 15px;
      border-right: 1px solid #ccc;
    }
    .menuItemName {
      width: 55%;
      padding: 5px 15px;
    }
  }
}
.playedSongTable {
  margin-bottom: 2rem;
}

.MuiSelect-select {
  height: auto !important;
  white-space: break-spaces;
}
.CustomDrpSelect {
  height: 36px;
  fieldset {
    border: 0px !important;
    height: auto !important;
  }
}