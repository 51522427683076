.PermissionTable {
  table {
    border-collapse: collapse;
    tr {
        th {
            background-color: #e0e0e0;
            font-weight: 400;
        }
      th,
      td {
        border: 1px solid #ccc;
      }
    }
  }
  .MuiToolbar-gutters {
    .MuiBox-root {
      width: 100%;
      position: unset;
      padding: 0;
      .MuiTablePagination-root {
        width: 100%;
        border: 1px solid #ccc;
        border-top: 0;
        margin-bottom: 10px;
        .MuiSelect-select {
          border: 1px solid #ccc;
          border-radius: 4px;
        }
      }
    }
  }
  .BtnPart {
    display: flex;
    align-items: center;
    gap: 15px;
    button {
      border: 1px solid #3c735c;
      min-width: unset;
      &:hover {
        background-color: #3c735c;
        svg {
          color: #fff;
        }
      }
    }
  }
}
.PermissionTable-dark {
  table {
    border-collapse: collapse;
    tr {
        th {
            background-color: #e0e0e0;
            font-weight: 400;
        }
      th{
        color: #000 !important;
      }
      td {
        border: 1px solid #ccc;
      }
    }
  }
  .MuiToolbar-gutters {
    .MuiBox-root {
      width: 100%;
      position: unset;
      padding: 0;
      .MuiTablePagination-root {
        width: 100%;
        border: 1px solid #ccc;
        border-top: 0;
        margin-bottom: 10px;
        .MuiSelect-select {
          border: 1px solid #ccc;
          border-radius: 4px;
        }
      }
    }
  }
  .BtnPart {
    display: flex;
    align-items: center;
    gap: 15px;
    button {
      border: 1px solid #3c735c;
      min-width: unset;
      &:hover {
        background-color: #3c735c;
        svg {
          color: #fff;
        }
      }
    }
  }
}