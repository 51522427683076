.duplicateDayDialog {
  .MuiDialog-paper {
    max-width: 800px;
    background: #34393f;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.35);
    border-radius: 10px;
    padding: 35px !important;
  }
  &__inner {
    max-width: 666px;
    margin: 0 auto;
    width: 100%;
  }
  &-title {
    font-weight: 700 !important;
    font-size: 20px !important;
    line-height: 23px !important;
    color: #d9d9d9;
    margin: 0 0 38px !important;
  }
  .MuiDialogContent-root {
    padding: 0;
  }
  .MuiDialogActions-root {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 25px;
    button {
      margin: 0 !important;
      background: #3b5c84;
      border-radius: 15px;
      box-shadow: none;
      height: 40px;
      min-width: 125px;
      padding: 12px;
      border: 2px solid #3b5c84;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: #ffffff;
      text-transform: capitalize;
      &:last-child {
        background: transparent;
        border: 2px solid #fff;
      }
    }
  }
}

.fieldBox {
  border: 2px solid #d9d9d9;
  border-radius: 5px;
  margin-bottom: 40px;
  &__head {
    background: #d9d9d9;
    padding: 10px 40px;
    &-title {
      font-weight: 700 !important;
      font-size: 16px !important;
      line-height: 18px !important;
      color: #3c434b;
    }
  }
  &__body {
    padding: 18px 40px;
    .MuiSelect-select {
      border: 1px solid #e8e8e8 !important;
      border-radius: 3px !important;
      height: 34px !important;
      box-sizing: border-box !important;
      padding: 8px 20px !important;
      font-weight: 400 !important;
      font-size: 14px !important;
      line-height: 16px !important;
      color: #d9d9d9 !important;
    }
    .selectBox {
      max-width: 400px;
      width: 100%;
    }
  }
  .dayRadioGroup {
    .MuiFormGroup-root {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
      gap: 15px;
      width: 100%;
      text-align: center;
      .MuiFormControlLabel-root {
        // display: block;
        margin: 0;
        display: flex;
        flex-direction: column;
        .MuiRadio-root {
          padding: 0 !important;
          margin-bottom: 8px;
        }
        span {
          font-weight: 400 !important;
          font-size: 16px !important;
          line-height: 18px !important;
          color: #d9d9d9;
        }
      }
    }
  }
  .dayCheckBoxGroup {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    gap: 15px;
    width: 100%;
    text-align: center;
    .MuiFormControlLabel-root {
      // display: block;
      margin: 0;
      display: flex;
      flex-direction: column;
      .MuiCheckbox-root {
        padding: 0 !important;
        margin-bottom: 8px;
      }
      span {
        font-weight: 400 !important;
        font-size: 16px !important;
        line-height: 18px !important;
        color: #d9d9d9;
      }
    }
  }
}
