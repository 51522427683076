.materialSiteTable {
  tr {
    td,
    th {
      &:first-child {
        width: 8%;
      }
      button{
        background-color: #3c735c !important;
      }
    }
  }
}
