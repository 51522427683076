.modelCloseBtn {
  float: right;
  color: #fff !important;
  font-size: 38px;
}
.addModelDialog {
  .MuiDialog-paper {
    background: #34393f;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.35);
    border-radius: 10px;
    padding: 35px !important;
  }
  &-title {
    font-weight: 700 !important;
    font-size: 20px !important;
    line-height: 23px !important;
    color: #d9d9d9;
    margin: 0 0 10px !important;
  }
  .MuiDialogActions-root {
    padding: 0;
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 25px;
    button {
      margin: 0 !important;
      background: #3c735c;
      border-radius: 15px;
      box-shadow: none;
      height: 40px;
      min-width: 125px;
      padding: 12px;
      border: 2px solid #3c735c;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: #ffffff;
      text-transform: capitalize;
      &:last-child {
        background: transparent;
        border: 2px solid #fff;
      }
    }
  }
}
.dialogForm {
  max-width: 500px;
  margin: 0 auto;
  width: 100%;
  &-field {
    display: grid;
    grid-template-columns: 130px auto;
    gap: 20px;
    align-items: center;
    margin-bottom: 20px;
    &-label {
      font-weight: 400 !important;
      font-size: 14px !important;
      line-height: 16px !important;
      color: #d9d9d9;
    }
    .MuiSelect-select {
      border: 2px solid #e8e8e8 !important;
      border-radius: 9px !important;
      height: 34px !important;
      box-sizing: border-box !important;
      padding: 8px 20px !important;
      font-weight: 400 !important;
      font-size: 14px !important;
      line-height: 16px !important;
      color: #d9d9d9 !important;
    }
  }
}

.dialogTable {
  .MuiTable-root thead tr:first-child {
    background-color: #d9d9d9 !important;
    tr {
      color: #d9d9d9 !important;
    }
  }
}
.dialogTable {
  .MuiTable-root tbody tr {
    background-color: #34393f !important;
    td {
      color: #d9d9d9 !important;
    }
  }
}

.modal-top-actions {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 25px;
  button {
    margin: 0 !important;
    background: #3c735c;
    border-radius: 15px;
    box-shadow: none;
    height: 40px;
    min-width: 125px;
    padding: 12px;
    border: 2px solid #3c735c;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #ffffff;
    text-transform: capitalize;
    &:last-child {
      background: transparent;
      border: 2px solid #c66969;
    }
  }
}

.selectBox {
  width: 90%;
  #simple-select {
    color: #e8e8e8 !important;
  }
}
.divider {
  font-size: 30px;
}
.addNewwheelDialog {
  .MuiDialog-paper {
    background: #f2f2f2;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.35);
    border-radius: 10px;
    padding: 35px !important;
  }
  &-title {
    font-weight: 700 !important;
    font-size: 20px !important;
    line-height: 23px !important;
    color: #d9d9d9;
    margin: 0 0 10px !important;
  }
  .MuiDialogActions-root {
    padding: 0;
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 25px;
    button {
      margin: 0 !important;
      background: #3c735c;
      border-radius: 15px;
      box-shadow: none;
      height: 40px;
      min-width: 125px;
      padding: 12px;
      border: 2px solid #3c735c;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: #ffffff;
      text-transform: capitalize;
      &:last-child {
        background: transparent;
        border: 2px solid #fff;
      }
      &.settings-btn {
        background: transparent;
        border: 2px solid #fff;
        min-width: auto;
      }
    }
  }
}

.btn-user-role {
  background-color: #34393f;
  text-align: left;
  &.css-toe7b3-MuiButtonBase-root-MuiButton-root {
    background-color: #34393f;
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #d9d9d9;
    border: none;
    box-shadow: none;
    padding: 18px 25px 12px 25px;
    text-transform: capitalize;
    display: block;
    border-radius: 0px;
  }
  &.css-toe7b3-MuiButtonBase-root-MuiButton-root:hover {
    background-color: #3c735c;
  }
  &:focus {
    background-color: #3c735c;
  }
}
.user-roles-wrapper {
  border: 2px solid #d9d9d9;
  display: inline-block;
  width: 325px;
  &.css-1hn5dus-MuiPaper-root {
    border-radius: 5px;
    background-color: transparent;
  }
}

.addNewwheelDialog.user-role-dialog .MuiDialog-paper {
  min-width: 530px !important;
  min-height: 575px;
}

.addNewwheelDialog .user-role-actions {
  justify-content: space-around;
}

.content{
  height: 186px;
}

.contentLibraryDialog{
  display: flex;
  align-items: center;
  margin-top: 10px;
}
