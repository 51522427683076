.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.main-centered {
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 150px;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.menuItem-Link span {
  font-weight: 400;
  font-size: 15px !important;
  line-height: 21px;
  color: #e8ebe6 !important;
}

.main-bg {
  background-image: linear-gradient(120deg, #0d324d, #7f5a83);
}
.player-main-text {
  font-family: Inter var, ui-sans-serif, system-ui, -apple-system,
    BlinkMacSystemFont, "Segoe UI", Roboto;
  font-weight: 700;
  font-size: 1.4rem;
  letter-spacing: 0.04rem;
}
.player-secondary-text {
  font-family: Inter var, ui-sans-serif, system-ui, -apple-system,
    BlinkMacSystemFont, "Segoe UI", Roboto;
  font-weight: 600;
  color: rgb(156 163 175);
  font-size: 1rem;
  letter-spacing: 0.02rem;
}

.navToolBar {
  padding: 25px 80px !important;
  height: 98px;
  box-sizing: border-box;
  justify-content: space-between;
}
.navToolBar-btn {
  display: flex;
  align-items: center;
  gap: 20px;
}
.navToolBar .MuiButtonBase-root {
  padding: 0;
  margin: 0;
  height: 98px;
  flex: auto;
}
.navToolBar .MuiButtonBase-root:hover {
  background-color: transparent !important;
}
.navToolBar .MuiButtonBase-root svg {
  height: 28px;
  width: 28px;
}
.navToolBar .MuiButtonBase-root h6 {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 17px;
  color: #3c434b;
  white-space: nowrap;
  padding: 0;
  margin: 5px 0 0;
}
.navToolBar .MuiButtonBase-root .MuiTouchRipple-root {
  display: none !important;
}
.seachBox {
  background: #e8e8e8;
  border-radius: 14px;
  height: 44px;
  max-width: 205px;
}
.seachBox .MuiInputBase-root {
  height: 44px;
}
.seachBox fieldset {
  height: 44px;
  border: none;
}

/*New Css Add*/
#mui-component-select-language {
  color: #e8ebe6 !important;
}

#mui-component-select-language .css-hfutr2-MuiSvgIcon-root-MuiSelect-icon,
#mui-component-select-language svg {
  color: #e8ebe6 !important;
}
/* MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiSelect-icon MuiSelect-iconOutlined css-hfutr2-MuiSvgIcon-root-MuiSelect-icon */
.materialTable .MuiToolbar-dense {
  background-color: #cc0000 !important;
  display: none;
}

.materialTable thead tr {
  background-color: #f2f2f2 !important;
}
/* .materialTable .css-jybny-MuiTableRow-root:hover {
  background-color: #fff !important;
} */
.materialTable .css-jybny-MuiTableRow-root:hover td {
  background-color: transparent;
}
.materialTable tbody tr {
  background-color: #fff !important;
  box-shadow: 0px 0px 1px 0px #97a8bb !important;
}

.materialTable thead tr th button {
  display: none !important;
}
.materialTable tbody tr td button svg {
  display: none;
}
.materialTable tbody tr td button {
  background-color: #42876a;
  color: #fff;
  position: relative;
}
.materialTable tbody tr td button::before {
  content: "";
  position: absolute;
  width: 14px;
  height: 2px;
  background-color: #fff;
  display: block;
}
.materialTable tbody tr td button::after {
  content: "";
  position: absolute;
  width: 2px;
  height: 14px;
  background-color: #fff;
  display: block;
  transition: all 0.5s ease;
}
.materialTable tbody tr td span[aria-label="Collapse"] button::after {
  height: 0;
}
.materialTable tbody tr td button:hover {
  background-color: #3c735c;
}

.materialTable .css-jybny-MuiTableRow-root {
  background-color: #fff;
}

.materialTable .css-1gljwbd-MuiButtonBase-root-MuiIconButton-root.Mui-disabled {
  display: none;
}

.toast-message {
  font-size: 12px;
}

/* .music-library-table-header-actions{} */
.music-library-table-header-actions button {
  color: #e8e8e8;
  height: 54px;
  background-color: #4d555e;
  width: 130px;
  /* height: 100%; */
}
.music-library-table-header-actions
  .music-library-table-header-actions-btn-clear {
  margin-right: 80px;
}
.music-library-table-header-actions .music-library-table-header-actions-btn {
  margin: 0 5px;
}

#channel-tools-label {
  color: #282c34 !important;
  text-transform: capitalize;
}

#channel-tools-label:hover{
  background-color: #E3EAE4 !important;
  color: #000 !important;
}

#channel-tools-label-dark {
  color: #fff !important;
  text-transform: capitalize;
}

#channel-tools-label-dark:hover{
  background-color: #E3EAE4 !important;
  color: #000 !important;
}
