.timeManagerForm {
    .dialogForm-field {
        margin: 20 0 2px !important;

        .MuiInputBase-root {

            .MuiSelect-select {
                border: 1px solid #fff !important;
                border-radius: 4px !important;
                height: 33px !important;
            }

            input {
                border: 1px solid #fff;
                border-radius: 4px;
                color: #fff;
                padding: 8px 5px 8px 5px;
                min-width: 100px;
                font-size: 14px;
                line-height: 14px;
                width: 352px;
            }

            .MuiInputAdornment-root {
                position: absolute;
                margin: 0 !important;
                right: 25px;
            }

            svg {
                display: block;
                color: #fff;
            }

            fieldset {
                border: none;
            }
        }
    }
}